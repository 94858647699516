/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React from 'react'
import { graphql } from 'gatsby'

// Components
import HomeTemplate from 'templates/home.js'

const Home = ({ data }) => {
  return (
    <HomeTemplate data={data} />
  )
}

export default Home

export const pageQuery = graphql`
  query($id: String) {
    page: wpPage(id: { eq: $id },locale: {locale: {eq: "nl_NL"}}, template: {templateName: { eq: "Home" }}) {
      ...HomeFrag
    }
  }
`